import React from "react";
import { Row, Col } from "react-bootstrap";

import Section from "../Section";
import Heading from "../Heading";

import styles from "./index.module.scss";

import iconLockPurple from "../../img/icon-lock-purple.svg";

class SecurityBucketGroup extends React.Component {
	render() {
		return (
			<Section extraLeftPadding={this.props.extraLeftPadding}>
				<Row className={styles.bucketGroupRow}>
					<Col>
						<Row>
							<Col className={styles.bucketGroupHeading}>
								<img
									src={iconLockPurple}
									className={styles.icon}
									alt=""
								/>
								<Heading
									text={this.props.headingText}
									themeType={this.props.themeType}
								/>
							</Col>
						</Row>
						<Row>{this.props.children}</Row>
					</Col>
				</Row>
			</Section>
		);
	}
}

export default SecurityBucketGroup;

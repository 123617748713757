import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link, injectIntl } from "gatsby-plugin-intl";
import  Markdown from 'react-remarkable';

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Section from "../../components/Section";
import Hero from "../../components/Hero";
import MotifColumn from "../../components/MotifColumn";
import SecurityBucketGroup from "../../components/SecurityBucketGroup";
import SecurityBucket from "../../components/SecurityBucket";
import Questions from "../../components/Questions";

import styles from "../../css/pages/security-page.module.scss";

import iconQuestion from "../../img/icon-question.svg";

const SecurityPage = ({intl}) => {
  return (
    <Layout>
      <Background>
        <Hero backgroundType={"dark"} type={"business"}>
          <MotifColumn>
            <h1>{intl.formatMessage({id: 'security.hero.title'})}</h1>
            <p>
              {intl.formatMessage({id: 'security.hero.text'})}
            </p>
          </MotifColumn>
        </Hero>
        <SecurityBucketGroup
          headingText={intl.formatMessage({id: 'security.buckets.title'})}
          extraLeftPadding={true}
        >
          <SecurityBucket
            title={intl.formatMessage({id: 'security.buckets.first.title'})}
            preamble={intl.formatMessage({id: 'security.buckets.first.text'})}
          >
            <p>
              {intl.formatMessage({id: 'security.buckets.first.more'})}
            </p>
          </SecurityBucket>
          <SecurityBucket
            title={intl.formatMessage({id: 'security.buckets.second.title'})}
            preamble={intl.formatMessage({id: 'security.buckets.second.text'})}
          >
            <p>
              {intl.formatMessage({id: 'security.buckets.second.more'})}
            </p>
          </SecurityBucket>
          <SecurityBucket
            title={intl.formatMessage({id: 'security.buckets.third.title'})}
            preamble={intl.formatMessage({id: 'security.buckets.third.text'})}
          >
            <p>
              {intl.formatMessage({id: 'security.buckets.third.more'})}
            </p>
          </SecurityBucket>
        </SecurityBucketGroup>
        <Section extraLeftPadding={true}>
          <Row>
            <Col>
              <Markdown>{intl.formatMessage({id: 'security.text'})}</Markdown>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={styles.FAQColumn}>
              <img src={iconQuestion} alt="" className={styles.icon} />
              {intl.formatMessage({id: 'security.footer.text'})}
              <Link to="/faq" className={styles.arrowLink}>
              {intl.formatMessage({id: 'security.footer.link.text'})}
              </Link>
            </Col>
          </Row>
        </Section>
        <Questions />
      </Background>
    </Layout>
  );
};

export default injectIntl(SecurityPage);
